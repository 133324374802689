<template>
  <div class="Forgotpassword-containertop">
    <div class="Forgotpassword-container">
      <div class="bg">
        <nav-bar :title="'身份驗證'"></nav-bar>
        <div class="Forgotpassword-form">
          <van-tabs
            v-model:active="active"
            title-active-color="#1A1A1A"
            title-inactive-color="#BDBDBD"
            @click="handleLoginTab"
          >
            <van-tab :title="$t('郵箱驗證')" name="2"></van-tab>
            <van-tab :title="$t('手機號驗證')" name="1"></van-tab>
          </van-tabs>
          <div v-if="active === '1'">
            <div class="phone" @click="show = true">
              <span>{{ phoneLabel }}</span>
              <img
                class="arrow"
                src="@/assets/icons/arrow-rightactive.png"
                alt=""
              />
            </div>
            <van-form>
              <div>
                <van-field
                  v-model="form.phone"
                  :placeholder="$t('請輸入創建帳號的手機號')"
                />
                <!-- <div class="error" v-if="verifyobj.userName">請輸入手機號</div> -->
              </div>
              <div>
                <van-field
                  v-model="form.captcha"
                  :type="'text'"
                  @click-right-icon="isPassword = !isPassword"
                  :placeholder="$t('請輸入驗證碼')"
                >
                  <template #right-icon>
                    <div>
                      <div
                        v-if="countDown == 0"
                        class="Verification-btn"
                        @click="getVerification"
                      >
                        {{ $t("獲取驗證碼") }}
                      </div>
                      <div v-else class="Verification-btn">
                        {{ countDown }}s
                      </div>
                    </div>
                  </template>
                </van-field>
                <!-- <div class="error" v-if="verifyobj.password">請輸入密码</div> -->
              </div>
              <div style="margin: 16px;">
                <van-button
                  @click="getNextForgotpassword"
                  class="Forgotpassword-btn"
                  block
                  type="info"
                  native-type="submit"
                  color="rgba(144, 209, 46, 1)"
                >
                  {{ $t("下一步") }}</van-button
                >
              </div>
            </van-form>
          </div>
          <div v-else>
            <van-form>
              <div>
                <van-field
                  v-model="form.email"
                  :placeholder="$t('請輸入註冊賬號的郵箱')"
                />
              </div>
              <div>
                <van-field
                  v-model="form.captcha"
                  :type="'text'"
                  @click-right-icon="isPassword = !isPassword"
                  :placeholder="$t('請輸入驗證碼')"
                >
                  <template #right-icon>
                    <div>
                      <div
                        v-if="countDown == 0"
                        class="Verification-btn"
                        @click="getVerification"
                      >
                        {{ $t("獲取驗證碼") }}
                      </div>
                      <div v-else class="Verification-btn">
                        {{ countDown }}s
                      </div>
                    </div>
                  </template>
                </van-field>
              </div>
              <div style="margin: 16px;">
                <van-button
                  class="Forgotpassword-btn"
                  @click="getNextForgotpassword"
                  block
                  type="info"
                  native-type="submit"
                  color="rgba(144, 209, 46, 1)"
                >
                  {{ $t("下一步") }}</van-button
                >
              </div>
            </van-form>
          </div>
        </div>
      </div>

      <van-popup
        v-model:show="show"
        position="bottom"
        :style="{ height: '40%', borderRadius: '16px 16px 0 0' }"
      >
        <div class="titleArea">
          <div></div>
          <div>{{ $t("國家地區") }}</div>
          <div class="close">
            <img
              @click="show = false"
              style="width: 100%"
              src="@/assets/icons/ic_Close@2x.png"
              alt=""
            />
          </div>
        </div>
        <div class="phone-list">
          <div
            :class="['phone-item', { actvie: item.code == selectPhone }]"
            v-for="item in phoneCode"
            :key="item.value"
            @click="handleAreaCode(item)"
          >
            <div class="phone-text">{{ item.name }}</div>
            <img
              class="phone-check"
              v-if="item.code == selectPhone"
              src="@/assets/icons/check.png"
              alt=""
            />
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { getLogin } from "@/api/login";
import { setToken } from "@/utils/token";
import JSEncrypt from "jsencrypt";
import { mapState } from "vuex";
import {
  getCaptchaNew,
  getvalidateEmail,
  getRegisterNew,
  validatePhoneCaptcha
} from "@/api/user";

export default {
  data() {
    return {
      form: {
        captcha: "",
        userName: "",
        password: "",
        errorCount: 0,
        autoLogin: false,
        areaCode: "+852",
        email: "",
        phone: ""
      },
      active: "3",
      isPassword: true,
      isEmail: false,
      isEyes: false,
      columns: [
        {
          value: "1",
          label: "HK(+852)"
        },
        {
          value: "2",
          label: "MO(+853)"
        }
      ],
      show: false,
      selectPhone: "1",
      phoneLabel: "HK(+852)",
      isRegister: false,
      verifyobj: {
        userName: "",
        password: ""
      },
      verifyList: [],
      countDown: 0
    };
  },
  computed: {
    ...mapState("user", ["phoneCode"])
  },
  async created() {
    await this.$store.dispatch("user/getPhoneCode");
    this.selectPhone = this.phoneCode[0]?.code;
    this.phoneLabel = this.phoneCode[0]?.name;
  },
  methods: {
    //获取验证码
    async getEmailCode() {
      const publicKey = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC/TS09a8Uez9/XfkDdK+Exx3ltVxrUlCjNPqRheMB/63iHyjx5k+N/48Nh9NFdCQKLEV/WxHQoszEBEh/sFofQuAx1wB9bcOtD98DT8Wkd53XmojxVfhtsVjeI/IBMim56mrO/LcKQDwc/6BsbFvilde002wx3CQ91OCw8XMptTwIDAQAB`;

      let jsEncrypt = new JSEncrypt();
      jsEncrypt.setPublicKey(publicKey);
      const emailParams = {
        email: this.form.email,
        type: "forgetpwd",
        captcha: ""
      };
      const phoneParams = {
        phone: this.form.phone,
        type: "forgetpwd",
        areaCode:this.form.areaCode,
        captcha: ""
      };
      let dataToEncrypt = JSON.stringify(this.active=='2' ? emailParams : phoneParams);
      console.log(dataToEncrypt);
      const res = await getCaptchaNew({
        id: jsEncrypt.encrypt(dataToEncrypt)
      });
      this.$toast(res.message);
      return res;
    },
    async getVerification() {
      console.log(this.form);

      const regxEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      const regxPhone = /^([5|6|8|9])\d{7}$/;

      if (this.active == "2") {
        if (!regxEmail.test(this.form.email) || !this.form.email) {
          return this.$toast(this.$t("請輸入有效的電郵地址"));
        }
      } else {
        console.log(this.form);
        if (!regxPhone.test(this.form.phone) || !this.form.phone) {
          this.$toast(this.$t("手機號碼格式不正確"));
          return;
        }
      }
      console.log(this.phoneLabel);

      if (this.active === "2") {
        this.form.areaCode = undefined;
      } else {
        this.form.areaCode = this.phoneLabel === "HK(+852)" ? "+852" : "+853";
      }

      const res = await this.getEmailCode();
      console.log(res);

      if (res.resultID === 1200) {
        this.countDown = 60;

        const timer = setInterval(() => {
          this.countDown--;
          if (this.countDown === 0) {
            clearInterval(timer); // 清除定时器
            this.countDown = 0;
          }
        }, 1000);
      }
    },
    handleLoginTab() {
      this.verifyList = [];
      // this.validatoForm();
    },
    gotoRegister() {
      this.isRegister = false;
      this.$router.push("/register");
    },
    // 注册
    openRegister() {
      // this.isRegister = true;
      this.$router.push("/register");
    },
    handleAreaCode(item) {
      this.selectPhone = item.code;
      this.show = false;
      this.form.areaCode = item.code;
      this.phoneLabel = item.name;
    },
    validatorPhone(val) {},
    validatorEmail(val) {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(val);
    },
    onChange(val) {
      this.form.areaCode = val;
      this.show = false;
    },
    async getNextForgotpassword() {
      const regxEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      const regxPhone = /^([5|6|8|9])\d{7}$/;
      console.log(this.form);
      this.verifyList = [];
      if (this.active == "2" && !regxEmail.test(this.form.email)) {
        return this.$toast(this.$t('請輸入有效的電郵地址'));
      } else if (this.active == "1" && !regxPhone.test(this.form.phone)) {
        return this.$toast(this.$t('手機號碼格式不正確'));
      }
      if (this.active === "2") {
        this.form.areaCode = undefined;
      } else {
        this.form.areaCode = this.phoneLabel === "HK(+852)" ? "+852" : "+853";
      }
      if (!this.form.captcha) {
        return this.$toast(this.$t('請輸入驗證碼'));
      }

      const res = await validatePhoneCaptcha({
        ...this.form,
        type: "forgetpwd"
      });
      if (res.resultID === 1200) {
        this.$router.push(
          `/resetpassword?email=${this.form.email}&phone=${this.form.phone}&phoneCaptcha=${this.form.captcha}&areaCode=${this.form.areaCode}`
        );
      } else {
        this.$toast(res.message);
      }
    }
  }
};
</script>

<style lang="scss">
.Forgotpassword-containertop {
  .Forgotpassword-container {
    .bg {
      height: 80px;
      background: linear-gradient(
        180deg,
        rgba(144, 209, 46, 0.3) 0%,
        rgba(144, 209, 46, 0) 100%
      );
      border-radius: 0px 0px 0px 0px;
      padding-top: 0;
    }
    .logo {
      width: 166px;
      margin: 46px 0 40px 32px;
    }
    .van-field {
      background-color: transparent;
      padding: 12px;
      border-bottom: 2px solid #ebebeb;
      .van-field__control {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      &::after {
        display: none;
      }
    }
    .van-tabs {
      width: 100%;
    }
    .van-tabs__nav {
      background-color: transparent;
      height: auto;
      .van-tabs__line {
        display: none;
      }
      .van-tab:first-child {
        border-right: 1px solid #bdbdbd;
      }
      .van-tab__text {
        font-size: 16px;
      }
    }
    .arrow {
      width: 16px;
      height: 16px;
    }
    .phone {
      display: flex;
      align-items: center;
      font-size: 15px;
      color: #1a1a1a;
      margin: 12px 0px 20px 14px;
    }
    .Forgotpassword-form {
      padding: 0 22px;
      padding-top: 80px;
      .error {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #f82207;
        line-height: 18px;
        padding-left: 12px;
        margin-top: 1px;
      }
      .Forgotpassword-btn {
        border-radius: 12px;
        margin-top: 24px;
      }
      .Verification-btn {
        color: #3491fa;
      }
    }
  }
  .Forgot {
    display: flex;
    justify-content: end;
    font-size: 15px;
    color: #1a1a1a;
    line-height: 24px;
    margin-top: 20px;
  }
  .register {
    text-align: center;
    color: #90d12e;
  }
  .titleArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 16px 0;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 17px;
    color: rgba(0, 0, 0, 0.9);
    line-height: 24px;

    .close {
      width: 20px;
    }
  }
  .phone-list {
    padding: 20px 16px;
    .actvie {
      border: 2px solid #90d12e !important;
    }
    .phone-item {
      padding: 20px;
      position: relative;
      background: #f8f8f8;
      border-radius: 8px 8px 8px 8px;
      margin-bottom: 12px;
      border: 2px solid transparent;
      .phone-text {
        font-size: 16px;
        color: #1a1a1a;
        line-height: 16px;
        text-align: center;
      }
      .phone-check {
        position: absolute;
        width: 24px;
        bottom: -1px;
        right: -1px;
      }
    }
  }
  .register-dialog {
    padding: 24px;
  }
  .register-text {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 15px;
    color: #666666;
    line-height: 24px;
    text-align: center;
    span {
      color: #3491fa;
    }
  }
  .register-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .Cancel,
    .Confirm {
      flex: 1;
      text-align: center;
      margin-top: 32px;
    }
    .Cancel {
      background: #eeeeee;
      border-radius: 12px 12px 12px 12px;
      padding: 8px;
      margin-right: 12px;
    }
    .Confirm {
      font-size: 16px;
      color: #ffffff;
      background-color: #90d12e;
      border-radius: 12px 12px 12px 12px;
      padding: 8px;
    }
  }
}
</style>
